import { inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { InitializeSelectors } from './+state/initialize.selectors';

@Injectable()
export class RouterStoreService {
  private readonly store = inject(Store);

  public readonly previousRoute = this.store.selectSignal(InitializeSelectors.previousRoute);

  public readonly previousRoutePrimaryNavigationPath = this.store.selectSignal(
    InitializeSelectors.previousRoutePrimaryNavigationPath,
  );

  public readonly currentRoute = this.store.selectSignal(InitializeSelectors.currentRoute);

  public readonly currentRoutePrimaryNavigationPath = this.store.selectSignal(
    InitializeSelectors.currentRoutePrimaryNavigationPath,
  );

  public readonly params = this.store.selectSignal(InitializeSelectors.selectRouteNestedParam);
  public selectParam(key: string): Signal<string | undefined> {
    return this.store.selectSignal(InitializeSelectors.selectRouteNestedParam(key));
  }
}
